// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-study-details-page-js": () => import("../src/templates/studyDetailsPage.js" /* webpackChunkName: "component---src-templates-study-details-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-statement-js": () => import("../src/pages/privacy-statement.js" /* webpackChunkName: "component---src-pages-privacy-statement-js" */),
  "component---src-pages-sitemap-js": () => import("../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-studies-js": () => import("../src/pages/studies.js" /* webpackChunkName: "component---src-pages-studies-js" */),
  "component---src-pages-study-js": () => import("../src/pages/study.js" /* webpackChunkName: "component---src-pages-study-js" */),
  "component---src-pages-terms-use-js": () => import("../src/pages/terms-use.js" /* webpackChunkName: "component---src-pages-terms-use-js" */),
  "component---src-pages-transparency-policy-js": () => import("../src/pages/transparency-policy.js" /* webpackChunkName: "component---src-pages-transparency-policy-js" */),
  "component---src-pages-what-are-clinical-trials-js": () => import("../src/pages/what-are-clinical-trials.js" /* webpackChunkName: "component---src-pages-what-are-clinical-trials-js" */),
  "component---src-pages-what-to-expect-js": () => import("../src/pages/what-to-expect.js" /* webpackChunkName: "component---src-pages-what-to-expect-js" */),
  "component---src-pages-why-participate-js": () => import("../src/pages/why-participate.js" /* webpackChunkName: "component---src-pages-why-participate-js" */)
}

