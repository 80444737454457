// Google api language codes mapper
const gApiLangCodeMapper = {
  pt: 'pt-BR',
  jp: 'ja',
  cn: 'zh-CN',
};

//get all languages available
export const getAllLanguages = () => {
  return ((process.env.GATSBY_ALLOWED_LANGUAGES || '').split(',') || []).concat(
    'en'
  );
};

// Return google api language code used to translate autocomplete suggestions and map
export const gApiLangCode = () => {
  const currentLang = getCurrentLangId();
  return gApiLangCodeMapper[currentLang] !== undefined
    ? gApiLangCodeMapper[currentLang]
    : currentLang;
};

// Return recaptcha key
export const reCaptchaKey = () =>
  process.env.GATSBY_RECAPTCHA_KEY ||
  '6LcpXd4UAAAAABG7ilvQgQAmobLvrJqn-4VaupNC';

// Return google api key
export const gMapApiKey = () =>
  process.env.GATSBY_GOOGLE_MAP_API_KEY ||
  'AIzaSyAFq1fItucvNN8W751EX2dHcankbZcdFdA';

// Return tenant id
export const tenantId = () => process.env.GATSBY_STAPI_TENANT_ID || 903288;

// Return base URL
export const baseUrl = () =>
  process.env.GATSBY_STAPI_BASE_URL || 'https://uat-stapi.trialscope.com';

export const pageTitleHandler = (pageTitle, siteTitle, currentURL) => {
  if (!currentURL || !currentURL.includes('study')) return pageTitle;

  const url = new URL(currentURL);
  const studyId = url.pathname.split('/')[2];

  return `${pageTitle} | Study ${studyId} | ${siteTitle}`;
};

// Return current language code
export const getCurrentLangId = () => {
  if (
    typeof window !== 'undefined' &&
    window.location &&
    window.location.hostname &&
    process.env.GATSBY_ALLOWED_LANGUAGES) {
    const splitHostname = window.location.hostname.includes(
      'bayer-engage.app-trialscope.com'
    )
      ? window.location.hostname.split('-')
      : window.location.hostname.split('.');
    let currentLang = process.env.GATSBY_ALLOWED_LANGUAGES.split(',').find(
      elem => elem === splitHostname[0]
    );
    if (currentLang !== undefined) {
      return currentLang;
    }
  }
  return 'en';
};

// Return current environment
export const getCurrentEnv = () => {
  if (
    typeof window !== 'undefined' &&
    window.location &&
    window.location.hostname
  ) {
    if (window.location.hostname.includes('dev-')) {
      return 'dev';
    }
    if (
      window.location.hostname.includes('uat-') ||
      window.location.hostname.includes('bayer-engage.app-trialscope.com')
    ) {
      return 'uat';
    }
  }
  return 'prod';
};

const getMasterdata = () => {
  const stapiUrl = new URL(baseUrl());
  let stapiOrigin = stapiUrl.origin;

  if (typeof window === 'undefined')
    return `${stapiOrigin}/api/study/codes/all?tenantId=${tenantId()}`;

  const currentLang = getCurrentLangId();
  const isProd = getCurrentEnv() === 'prod';
  const isUat = getCurrentEnv() === 'uat';

  if (currentLang !== 'en' && (isProd || isUat)) {
    const stapiSubdomain = stapiUrl.host.split('.')[0];
    stapiOrigin = stapiOrigin.replace(
      stapiSubdomain,
      `${currentLang}-${stapiSubdomain}`
    );
  }

  return `${stapiOrigin}/api/study/codes/all?tenantId=${tenantId()}`;
};

export const masterDataUrl = getMasterdata();

// Return the hrefs used for switching languages
export const setRedirectUrl = newLang => {
  const currentLang = getCurrentLangId();
  const currentEnv = getCurrentEnv();
  if (
    typeof window !== 'undefined' &&
    window.location &&
    window.location.hostname &&
    window.location.href
  ) {
    const baseHostName = window.location.hostname.includes(
      'bayer-engage.app-trialscope.com'
    )
      ? window.location.hostname.replace(`${currentLang}-`, '')
      : window.location.hostname.replace(`${currentLang}.`, '');
    return currentEnv.includes('prod')
      ? window.location.href.replace(
          window.location.hostname,
          `${
            newLang !== 'en' ? `${newLang}.${baseHostName}` : `${baseHostName}`
          }`
        )
      : window.location.href.replace(
          window.location.hostname,
          `${
            newLang !== 'en' ? `${newLang}-${baseHostName}` : `${baseHostName}`
          }`
        );
  }
  return `${newLang}.clinicaltrials.bayer.com`;
};

export const getCanonicalURL = (canonical = '', availableLanguages) => {
  if (typeof window === 'undefined') return;

  if (canonical) return canonical;
  const currentUrl = new URL(window.location.href);
  const regex = new RegExp(`^(${availableLanguages.join('|')})\\.`);
  const match = currentUrl.hostname.match(regex);

  if (match) {
    const languageToRemove = match[1];
    currentUrl.hostname = currentUrl.hostname.replace(
      `${languageToRemove}.`,
      ''
    );
  }
  return currentUrl.href;
};

// Return the stapi URL used for request endpoints
export const apiUrl = () => {
  if (typeof window !== 'undefined') {
    let domain = process.env.GATSBY_STAPI_BASE_URL;
    (process.env.GATSBY_ALLOWED_LANGUAGES || '').split(',').forEach(lg => {
      if (lg.length) {
        domain = domain.replace(`https://${lg}-`, '');
      }
    });
    return domain.replace('https://', '');
  }
  return 'stapi.trialscope.com';
};

// Return endpoint for study search
export const searchTrialUrl = () => {
  const currentLang = getCurrentLangId();
  return `https://${
    currentLang !== 'en' ? `${currentLang}-` : ''
  }${apiUrl()}/api/study/search?tenantId=${tenantId()}&_cache=${new Date()}`;
};

// Return endpoint for contact form
export const submitFormUrl = () => {
  const currentLang = getCurrentLangId();
  return `https://${
    currentLang !== 'en' ? `${currentLang}-` : ''
  }${apiUrl()}/api/contact-us/submit?tenantId=${tenantId()}`;
};

// Return endpoint for study details
export const trialDetailsUrl = (studyId, typeOfId) => {
  const currentLang = getCurrentLangId();
  // Replace "studyId" with "Id" depending on ids used by customer
  return `https://${
    currentLang !== 'en' ? `${currentLang}-` : ''
  }${apiUrl()}/api/study/?tenantId=${tenantId()}${
    // typeOfId is used to determine if is an old internal Id or is a customer unique Id
    typeOfId === 'old' ? '&Id=' : '&uniqueStudyId='
  }${studyId}&_cache=${new Date()}`;
};

export const cookieBannerToken = () => {
  if (getCurrentLangId() === 'jp') {
    return '01904906-8284-7c37-aea3-22f2571b490e';
  }
  return '018f783c-fa41-79ff-af4a-4e2304fdb741';
}

// Return endpoint for all study ids
export const allTrialsIds = () => {
  const currentLang = getCurrentLangId();
  return `https://${
    currentLang !== 'en' ? `${currentLang}-` : ''
  }${apiUrl()}/api/study/ids/all?tenantId=${tenantId()}&_cache=${encodeURIComponent(
    new Date().toUTCString()
  )}`;
};

export const connectApiUrl = () =>
  process.env.GATSBY_CONNECT_API_ENDPOINT ||
  'https://connect-staging.trialscope.com/api/v1';
export const connectApiToken = () =>
  process.env.GATSBY_CONNECT_API_TOKEN || 'a96ded30970c9639060d628ab07ae78c';
