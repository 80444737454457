import React from 'react';
import AppProvider from 'store/provider';
import wrapPageElementWithTransition from 'helpers/wrapPageElement';
import 'bootstrap/dist/css/bootstrap.css';
import 'core-js/modules/es6.array.from';
import { MasterDataProvider } from 'store/masterDataContext';
// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return (
    <MasterDataProvider>
      <AppProvider>{element}</AppProvider>
    </MasterDataProvider>
  );
};

// Page Transitions
export const wrapPageElement = wrapPageElementWithTransition;

export const onRouteUpdate = win => {
  if (win.location && win.location.hash) {
    setTimeout(() => {
      if (document.querySelector(`${win.location.hash}`)) {
        document.querySelector(`${win.location.hash}`).scrollIntoView(true);
      }
    }, 1000);
  } else {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }
};

export const onClientEntry = () => {
  if (typeof window !== 'undefined' && window.location && window.location.href)
    if (window.location.href.includes('/en/')) {
      window.location.href = window.location.href.replace('/en/', '/');
    }
};
